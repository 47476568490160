import React from 'react'
import PageComponent from 'components/pageComponent'

const BlogPage = () => {
  return (
    <PageComponent>
      <div>blog sweet blog</div>
    </PageComponent>
  )
}

export default BlogPage