import React from 'react'
import PageComponent from 'components/pageComponent'

const HomePage = () => (
  <PageComponent>
    <div>home sweet home</div>
    <img src="img/angry-one.jpg" alt="Angry Shroom" />
  </PageComponent>
)

export default HomePage